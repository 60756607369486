define('Cubix_CategoryAttributes/js/view/messages-mixin',[
    'Magento_Customer/js/customer-data',
    'jquery'
], function (customerData,$) {
    'use strict';

    return function (target) {
        return target.extend({
            /**
             * Extends Component object by storage observable messages.
             */
            initialize: function () {
                this._super();
                var self = this;
                self.messages.subscribe(function(messages) {
                    
                    if (messages.messages) {
                        if (messages.messages.length > 0) {
                            $('#message-modal').css('display', 'flex');
                            setTimeout(function() {
                                customerData.set('messages', {});
                                $('#message-modal').hide();
                            }, 3000);
                        }
                    }
                });
            }
        });
    }
});
