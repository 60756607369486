define('Cubix_Newsletter/js/newsletter-subscription',[
    'jquery',
    'mage/translate',
    'mage/mage',
], function ($, $t) {
    'use strict';

    $.widget('cubix.Newsletter', {

        /** @inheritdoc */
        _create: function () {
            this._bindSubmit();
        },

        /**
         * @private
         */
        _bindSubmit: function () {
            var self = this;

            this.element.on('submit', function (e) {
                e.preventDefault();
                if ($(this).validation('isValid')) {
                    self.submitForm($(this));
                }
            });
        },

        /**
         * Handler for the form 'submit' event
         *
         * @param {Object} form
         */
        submitForm: function (form) {
            var self = this;
            $.ajax({
                url: form.attr('action'),
                data: form.serialize(),
                type: 'post',
                dataType: 'json',
                showLoader: false,
                /** @inheritdoc */
                beforeSend: function () {
                    self.element.parent().find('.messages').remove();
                    $('#dh_loading').show();
                },
                success: function (response) {
                    if (response.error) {
                        self.element.after('<div id="subscribed-error-message" class="messages"><div class="message message-error error"><div>'+response.message+'</div></div></div>');
                        $('#subscribe-btn').removeAttr("disabled");
                        // After 5 seconds, fade them back in
                        setTimeout(function() {
                            $('#subscribed-error-message').fadeOut();
                        }, 5000);
                    } else {
                        self.element.find('input').val('');
                        self.element.after('<div id="subscribed-success-message" class="messages"><div class="message message-success success"><div>'+response.message+'</div></div></div>');

                        // Fade out the submit button and input field
                        $('#subscribe-btn').fadeOut();
                        $('#newsletter').fadeOut();

                        // After 5 seconds, fade them back in
                        setTimeout(function() {
                            self.element.find('input').val('');
                            $('#subscribe-btn').removeAttr("disabled");
                            $('#subscribe-btn').fadeIn();
                            $('#newsletter').fadeIn();
                            $('#subscribed-success-message').fadeOut();
                        }, 5000);
                    }
                    $('#dh_loading').hide();
                },
                error: function() {
                    self.element.after('<div class="messages"><div class="message message-error error"><div>'+$t('An error occurred, please try again later.')+'</div></div></div>');
                    $('#dh_loading').hide();
                }
            });
        }
    });

    return $.cubix.Newsletter;
});
